/*
 * @Author: Terry
 * @Date: 2024-09-05 11:35:46
 * @LastEditors: Terry
 * @LastEditTime: 2024-09-05 11:36:10
 * @Description: file content
 * @FilePath: \auxface_h5\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import ElementUI from 'element-ui';
import live from './components/Live.vue';
import yun from './components/YunVideo.vue';
import preview from './components/preview.vue';
import camera from './components/camera.vue';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueRouter);

const routes = [
    { path: '/index', component: live },
    { path: '/yun', component: yun },
    { path: '/camera',component: camera },
    { path: '/preview',component: preview },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});
 
  
new Vue({
    router,
  render: h => h(App),
}).$mount('#app')
