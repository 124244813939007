<template>
    <div class="main">
        <div id="videoList">
            <div class="preview-video" @click.stop="checkIndex(index)"  v-for="(item,index) in list" >
                <div :id="'video-container'+index" :style="{'height': height+'px'}"@click="init(index)">
                    {{ index }}
                </div>
            </div>
        </div>
        <div class="button-group">
            <!-- <button class="button" v-on:click="init">init</button> -->
            <!-- <button class="button" v-on:click="stop">暂停</button>
            <button class="button" v-on:click="play">开始</button> -->
            <!-- <button class="button" v-on:click="openSound">打开声音</button> -->
            <!-- <button class="button" v-on:click="closeSound">关闭声音</button> -->
            <button class="button" v-on:click="startSave">开始录像</button>
            <!-- <button class="button" v-on:click="stopSave">结束录像</button> -->
            <button class="button" v-on:click="capturePicture">截图</button>
            <button class="button" v-on:click="openPtz">云台</button>
            <button class="button" v-on:click="fullScreen">全屏</button>
            <button class="button" v-on:click="ezopenStartTalk">开始对讲</button>
            <!-- <button class="button" v-on:click="ezopenStopTalk">结束对讲</button> -->
        </div>
    </div>
  </template>
<script>
import EZUIKit from "ezuikit-js";
import { getAction } from '@/api/manage'
export default {
    name: "Live",
    props: {
        msg: String
    },
    data() {
        return {
            player: [],
            live: true,
            loading: true,
            template: 'mobileLive',
            url: "ezopen://open.ys7.com/1/1.live",
            data: {
                token: 'at.b9foycowbqb1eed35yfses74av33e6hy-73tw2tj6uc-1ycxz22-cvo50wzmt',
                number: '',
                channelNo: ''
            },
            list:[],
            videoList:[],
            queryParam: {
                page: 1,
                limit: 10,
                count:0,
                uuid:'',
            },
            camPlayer:null,
            camIndex:-1,
            width: void 0,
            height: void 0,
        }
    },
    created() {
        const { Yuuid } = this.$route.query
        this.queryParam.uuid = Yuuid
        this.width = document.documentElement.clientWidth;
        this.height = (document.documentElement.clientWidth * 9 / 16) + 32;
    },
    mounted() {
        this.getList()
        setTimeout(()=>{
            this.elementView()
        },3000)
        window.addEventListener('scroll',this.handleScroll)
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        getList(){
            return new Promise((resolve,reject) =>{
                getAction('/video/admin/v1/camera/getChannelList', this.queryParam).then(res =>{
                    this.list = this.list.concat(res.data.list)
                    this.list.forEach((item,index) => {
                        this.getCamera(item.uuid,index)
                    })
                    this.queryParam.count = res.data.count
                    resolve()
                })
            })
        },
        getCamera(uuid,index) {
            this.loading = true
            getAction('/video/admin/v1/camera/getCameraDetail', { uuid: uuid }).then(res => {
                this.data.token = res.data.token
                this.data.number = res.data.number
                this.data.channelNo = res.data.channelNo
                let url = `ezopen://open.ys7.com/` + this.data.number + `/` + this.data.channelNo+ `.live`
                this.videoList[index] = url
                this.loading = false
            })
        },
        handleScroll(event) {
            console.log(event,"scroll===")
            const { clientHeight, scrollHeight } = event.target.body;
            const scrollTop = document.documentElement.scrollTop
            if (scrollTop + clientHeight >= scrollHeight) {
                // 触发滚动到底部的操作
                console.log('滚动到底部了');
                this.queryParam.page++
                if(this.queryParam.page<=Math.ceil(this.queryParam.count/this.queryParam.limit)){
                    this.getList()
                }else{
                    this.bottom = true
                }
            }
            this.elementView()
        },
        elementView(){
            var div = document.getElementById('videoList')
            let elements = []
            div.childNodes.forEach(item=>{
                elements.push(item.childNodes[0])
            })
            console.log("w jinlaile",elements)
            elements.forEach((element,index) => {
                // 检查元素是否可见
                const isVisible = this.isElementInViewport(element);
                // 执行对可见元素的操作
                if (isVisible) {
                    element.click()
                }else{
                    if(this.player[index]){
                        this.stop(index)
                    }
                }
            });
        },
        // 检查元素是否在视口中
        isElementInViewport(element) {
            // 获取元素的边界框
            const rect = element.getBoundingClientRect();
            // 检查元素是否在视口的顶部和底部之间
            return (
                rect.top >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
            );
        },
        init(index) {
            console.log("init",index)
            this.player[index] = new EZUIKit.EZUIKitPlayer({
                autoplay: true,
                id: "video-container"+index, // 视频容器ID
                accessToken: this.data.token,
                url: this.videoList[index],
                // simple: 极简版; pcLive: pc直播; pcRec: pc回放; mobileLive: 移动端直播; mobileRec: 移动端回放;security: 安防版; voice: 语音版;
                // template: this.template,
                // plugin: ["talk"], // 加载插件，talk-对讲
                width: this.width,
                height: this.height,
                useHardDev:true,
                // language: "en", // zh | en
                // staticPath: "/ezuikit_static", // 如果想使用本地静态资源，请复制根目录下ezuikit_static 到当前目录下， 然后设置该值
                   // 设置按钮
                themeData: {
                    poster:'',
                    header: {
                        btnList: [
                    
                        ],
                    },
                    footer: {
                        color: '#FFFFFF',
                        activeColor: '#1890FF',
                        backgroundColor: '#00000080',
                        btnList: [
                        {
                            iconId: 'play',
                            part: 'left',
                            defaultActive: 1,
                            memo: '播放',
                            isrender: 1,
                        },
                        {
                            iconId: 'capturePicture',
                            part: 'left',
                            defaultActive: 0,
                            memo: '截屏按钮',
                            isrender: 1,
                        },
                        {
                            iconId: 'sound',
                            part: 'left',
                            defaultActive: 0,
                            memo: '声音按钮',
                            isrender: 1,
                        },
                        // {
                        //     iconId: "pantile",
                        //     part: "left",
                        //     defaultActive: 0,
                        //     memo: "云台控制按钮",
                        //     isrender: 1
                        // },
                        {
                            iconId: 'recordvideo',
                            part: 'left',
                            defaultActive: 0,
                            memo: '录制按钮',
                            isrender: 1,
                        },
                        {
                            iconId: 'talk',
                            part: 'left',
                            defaultActive: 0,
                            memo: '对讲按钮',
                            isrender: 1,
                        },
                        {
                            iconId: 'zoom',
                            part: 'left',
                            defaultActive: 0,
                            memo: '电子放大',
                            isrender: 1,
                        },
                        {
                            iconId: 'hd',
                            part: 'right',
                            defaultActive: 0,
                            memo: '清晰度切换按钮',
                            isrender: 1,
                        },
                        {
                            iconId: 'webExpend',
                            part: 'right',
                            defaultActive: 0,
                            memo: '全屏按钮',
                            isrender: 1,
                        },
                        ],
                    },
                },
            });
            // window.player = this.player;
        },
        checkIndex(index){
            console.log("video====",index)
            this.camIndex = index
        },
        openPtz() {
            this.camPlayer = new EZUIKit.EZUIKitPlayer({
                id: "video-container"+this.camIndex, // 视频容器ID
                accessToken: this.data.token,
                url: this.videoList[this.camIndex],
                width: this.width,
                template: 'mobileLive',
                height: this.height,
            })
        },
        callback(val) {
            if (this.live == val) return
            if (this.live) {
                this.template = 'mobileRec'
                this.url = this.urlRec || this.url
            } else {
                this.template = 'mobileLive'
                this.url = this.urlLive || this.url
            }
            this.live = val
            this.init()
        },
        handleYun() {
            this.$router.push({
                path: '/yun',
                query: {
                    Yuuid: this.Yuuid
                }
            })
        },
        play(index) {
            var playPromise = this.player[index].play();
            playPromise.then((data) => {
                console.log("promise 获取 数据", data);
            });
        },
        stop(index) {
            var stopPromise = this.player[index].stop();
            stopPromise.then((data) => {
                console.log("promise 获取 数据", data);
            });
        },
        getOSDTime() {
            var getOSDTimePromise = this.player.getOSDTime();
            getOSDTimePromise.then((data) => {
                console.log("promise 获取 数据", data);
            });
        },
        capturePicture() {
            var capturePicturePromise = this.player.capturePicture(
                `${new Date().getTime()}`
            );
            capturePicturePromise.then((data) => {
                console.log("promise 获取 数据", data);
            });
        },
        openSound() {
            var openSoundPromise = this.player.openSound();
            openSoundPromise.then((data) => {
                console.log("promise 获取 数据", data);
            });
        },
        closeSound() {
            var openSoundPromise = this.player.closeSound();
            openSoundPromise.then((data) => {
                console.log("promise 获取 数据", data);
            });
        },
        startSave() {
            var startSavePromise = this.player.startSave(`${new Date().getTime()}`);
            startSavePromise.then((data) => {
                console.log("promise 获取 数据", data);
            });
        },
        stopSave() {
            var stopSavePromise = this.player.stopSave();
            stopSavePromise.then((data) => {
                console.log("promise 获取 数据", data);
            });
        },
        ezopenStartTalk() {
            this.player.startTalk();
        },
        ezopenStopTalk() {
            this.player.stopTalk();
        },
        fullScreen() {
            this.player[this.camIndex].fullScreen();
        },
        destroy() {
            var destroyPromise = this.player.destroy();
            destroyPromise.then((data) => {
                console.log("promise 获取 数据", data);
            });
            this.player = null;
        }
    },
    //   destroyed(){
    //     this.destroy();
    //   },
};
</script>
<style>
body {
    margin: 0;
}
</style>
<style lang="scss" scoped>
.main {
    height: 100vh
}
.preview-video {
    background: #000;
    border-bottom: 1px solid #eee;
    &>:deep(.mobile-ez-ptz-container){
        position: absolute;
    }
}
.button-group{
    position: fixed;
    bottom:0
}
:deep(#mobile-ez-ptz-item .mobile-ez-ptz-container) {
    width: 80px;
    height: 80px;
}
:deep(.header-controls){
    display: none !important;
}
.btn_div {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 50px;
    background: #fff;
    display: flex;
    justify-content: space-around;
    .btn_box {
        display: flex;
        flex-direction: column;
        align-items: center;

        &>i {
            font-size: 22px;
        }

        &.active {
            color: #1989fa;
        }

        .button {
            font-size: 14px;
        }
    }
}
</style>