import axios from '@/utils/axios'

export function postAction(url, parameter, headers) {
    return axios({
        url: url,
        method: 'post',
        data: parameter,
        headers
    })
}
export function httpAction(url, parameter, method) {
    return axios({
        url: url,
        method: method,
        data: parameter
    })
}

export function putAction(url, parameter) {
    return axios({
        url: url,
        method: 'put',
        data: parameter
    })
}

export function getAction(url, parameter, headers) {
    return axios({
        url: url,
        method: 'get',
        params: parameter,
        headers
    })
}