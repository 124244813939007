<!--
 * @Author: Terry
 * @Date: 2024-09-04 18:23:21
 * @LastEditors: Terry
 * @LastEditTime: 2024-09-04 19:14:43
 * @Description: file content
 * @FilePath: \auxface_h5\src\App.vue
-->
<template>
  <div id="app">
    <!-- <EZUIKitJs v-if="!yun" key="live"/>
    <YunVideo v-else-if="yun" key="yun" /> -->
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  data(){
    return{
        yun:false 
    }
  },
  created(){
    const { token,uuid,corpCode,puuid }  = this.$route.query;
    // let token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJBVVhGQUNFIiwiYXVkIjoiIiwiaWF0IjoxNzIzNzczMzAzLCJuYmYiOjE3MjM3NzMzMDMsImV4cCI6MTcyNDQ5MzMwMywidXVpZCI6IkFBNkVCRjYwNjA3RTExRUJCMDZFMDAxNjNFMDY1MzRBIn0.aTkUYbgmN7Utr24Wki3Qv7_aqvGDsAVqk66Q2GuRG_k'
    // let uuid = 'AA6EBF60607E11EBB06E00163E06534A'
    // let corpCode = '12345678988'
    // let puuid = '82240329010001'
    sessionStorage.setItem("auxfaceToken",token)
    sessionStorage.setItem("uuid",uuid)
    sessionStorage.setItem("puuid", puuid);
    sessionStorage.setItem("corpCode", corpCode);
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
