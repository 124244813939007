<template>
    <div class="main">
      <div id="video-container">
        <video :src="mp4Url" controls id="video" :width="width" :height="height"></video>
        <span class="divider_text">
            <span class="bqtitle">
                <span>回放列表</span>
                <i class="el-icon-refresh-left" @click="init($route.query.Yuuid)"></i>
            </span>
            <el-date-picker
                v-model="queryParam.date"
                :editable=false
                size="mini"
                type="date"
                value-format="yyyy-MM-dd"
                class="time_div"
                :picker-options="pickerOptions"
                @change="handleSearch"
                placeholder="选择日期">
            </el-date-picker>
            <!-- <el-time-picker
                is-range
                :editable=false
                v-model="time1"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围">
            </el-time-picker> -->
        </span>
        <div class="box_list" :style="'max-height:calc(100vh - '+(height+120)+'px'" @scroll="handleScroll"  v-loading="loading">
            <template v-if="list.length > 0">
                <div :class="['box_item',item.uuid == curIndex ? 'selected' : '']" v-for="(item,index) in list" :key="item.uuid" @click="handleClick(index)">
                    <div class="box_item_left">
                        <div class="sortIndex">{{index+1}}. </div>
                        <div class="column">
                            <div>
                                <!-- <span class="datetitle">开始时间：</span> -->
                                <span class="datefont">{{item.startTime}}</span>~<span class="datefont">{{ item.endTime }}</span>
                            </div>
                            <div class="time">时长：<span>{{ item.duration }}</span></div>
                        </div>
                    </div>
                    <div>
                        <i class="el-icon-video-play" style="font-size: 28px;"></i>
                    </div>
                </div>
                <div v-if="bottom" class="bottom_text">已拉到最底部</div>
            </template>
            <template v-else>
                <div class="noData">
                    <img class="no-data-img" src="@/assets/pic_list_empty.png" alt="no_data" />
                    <div>暂无数据</div>
                </div>
            </template>
        </div>
      </div>
      <div class="btn_div">
        <div class="btn_box" v-on:click="handleYun(1)">
            <i class="el-icon-video-play"></i>
            <span class="button">直播</span>
        </div>
        <div class="btn_box" v-on:click="handleYun(0)">
            <i class="el-icon-video-play"></i>
            <span class="button">回放</span>
        </div>
        <div class="btn_box active">
            <i class="el-icon-cloudy"></i>
            <span class="button">云回放</span>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {getAction} from '@/api/manage'
  export default {
    name: "YunVideo",
    data(){
      return{
        pickerOptions:this.reportTime(),
        dates:[],
        list:[],
        value1:'',
        curIndex:-1,
        time1:'',
        live:true,
        loading:false,
        bottom:false,
        queryParam:{
            page:1,
            limit:30,
            count:0,
            uuid:'',
            date:'',
        },
        mp4Url:"",
        width: void 0,
        height:void 0,
      }
    },
    created(){
        this.width = document.documentElement.clientWidth;
        this.height = (document.documentElement.clientWidth * 9 / 16);
        document.title = '云回放'
        // let token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJBVVhGQUNFIiwiYXVkIjoiIiwiaWF0IjoxNzIzNzczMzAzLCJuYmYiOjE3MjM3NzMzMDMsImV4cCI6MTcyNDQ5MzMwMywidXVpZCI6IkFBNkVCRjYwNjA3RTExRUJCMDZFMDAxNjNFMDY1MzRBIn0.aTkUYbgmN7Utr24Wki3Qv7_aqvGDsAVqk66Q2GuRG_k'
        // sessionStorage.setItem("auxfaceToken",token)
    },
    mounted(){
        const {date, Yuuid}  = this.$route.query;
        // this.queryParam.date = date;
        this.queryParam.uuid = Yuuid;
        this.init(Yuuid)
    },
    methods: {
        async init(Yuuid){
            await this.getDates(Yuuid)
            // this.queryParam.date=this.dates.at(-1)
            this.getList()
        },
        handleSearch(){
            this.list = []
            this.bottom = false
            this.queryParam.page = 1
            this.getList()
        },
        getList(){
            this.loading = true
            if(!this.queryParam.date){
                this.queryParam.date = '2024-08-20'
            }
            getAction('/video/admin/v1/camera/getCloudList',this.queryParam).then(res=>{
                this.list = this.list.concat(res.data.list)
                this.queryParam.count = res.data.count
            }).finally(res=>{
                this.loading = false;
            })
        },
        handleClick(index){
            this.mp4Url = this.list[index].videoUrl
            this.curIndex = this.list[index].uuid
            const video = document.getElementById("video")
            video.play();
        },
        handleScroll(event) {
            const { scrollTop, clientHeight, scrollHeight } = event.target;
            if (scrollTop + clientHeight >= scrollHeight) {
                // 触发滚动到底部的操作
                console.log('滚动到底部了');
                this.queryParam.page++
                if(this.queryParam.page<=Math.ceil(this.queryParam.count/this.queryParam.limit)){
                    this.getList()
                }else{
                    this.bottom = true
                }
            }
        },
        handleYun(live) {
            this.$router.push({ 
                path: '/index',
                query:{
                    Yuuid:this.queryParam.uuid,
                    live:live
                } 
            })
        },
        reportTime(){
            const that = this
            return {
                disabledDate(time){
                    return !that.dates.includes(time.getTime())
                }
            }
        },
        getDates (uuid) {
            getAction('/video/admin/v1/camera/getCloudDate',{uuid:uuid}).then((res) =>{
                this.dates = res.data.map(item => {
                    return new Date(item).setHours(0)
                })
                if(res.data.length>0){
                    this.queryParam.date = res.data[res.data.length-1]
                }
            })
        }
    },
  };
  </script>
<style scoped lang="scss">
.box_list{
    padding: 0 15px 15px;
    overflow: auto;
}
.time_div{
    width: auto !important;
}
.box_item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border: 1px solid #00000033;
    background: #f7f7f7;
    border-radius: 8px; */
    /* margin-top: 20px; */
    cursor: pointer;
    padding: 10px 15px 10px;
    font-size: 14px;
    &:not(:last-child){
        border-bottom: 1px solid #00000033;
    }
    .box_item_left{
        display: flex;
        align-items: center;
        .sortIndex{
            font-size: 16px;
            margin-right: 10px;
            font-weight: bold;
        }
    }
    .datetitle{color:#828282}
    .column{
        display: flex;
        flex-direction: column;
        height: 40px;
        justify-content: space-between;
        .time{
            color:#828282;
            text-align: left;
        }
    }
}
.btn_div{
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 50px;
    background: #fff;
    display: flex;
    justify-content: space-around;
    .btn_box{
        display: flex;
        flex-direction: column;
        align-items: center;
        &>i{
            font-size: 22px;
        }
        &.active{
            color:#1989fa;
        }
        .button{
            font-size: 14px;
        }
    }
}
.divider_text{
    font-size: 18px;
    font-weight: bold;
    color: #000000d9;
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding: 0 15px 20px;
    .bqtitle{
        display: flex;
        flex:0 0 auto;
        align-items: center;
    }
    &::before{
        content: '';
        display: inline-block;
        width: 4px;
        height: 17px;
        background: #108EE9FF;
        position: absolute;
        top: 6px;
        left: 0px;
    }
}
.selected{
  background:rgb(217, 236, 255);

}
.bottom_text{
    text-align: center;
    padding: 10px 0;
    color: #888;
}
.noData {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: rgba(0,0,0,0.65);
    .no-data-img {
        width: 78px;
        margin: 16px 0 11px;
    }
}
  </style>