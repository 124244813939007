<template>
  <div class="main" v-loading="loading">
    <div id="video-container"></div>
    <div class="btn_div">
        <div :class="['btn_box',live?'active':'']" v-on:click="callback(1)">
            <i class="el-icon-video-play"></i>
            <span class="button">直播</span>
        </div>
        <div :class="['btn_box',!live?'active':'']" v-on:click="callback(0)">
            <i class="el-icon-video-play"></i>
            <span class="button">回放</span>
        </div>
        <div class="btn_box" v-on:click="handleYun">
            <i class="el-icon-cloudy"></i>
            <span class="button">云回放</span>
        </div>
      <!-- <button class="button" v-on:click="init">init</button>
      <button class="button" v-on:click="stop">暂停</button>
      <button class="button" v-on:click="play">开始</button>
      <button class="button" v-on:click="openSound">打开声音</button>
      <button class="button" v-on:click="closeSound">关闭声音</button>
      <button class="button" v-on:click="startSave">开始录像</button>
      <button class="button" v-on:click="stopSave">结束录像</button>
      <button class="button" v-on:click="capturePicture">截图</button>
      <button class="button" v-on:click="fullScreen">全屏</button>
      <button class="button" v-on:click="ezopenStartTalk">开始对讲</button>
      <button class="button" v-on:click="ezopenStopTalk">结束对讲</button> -->
    </div>
  </div>
</template>

<script>
import EZUIKit from "ezuikit-js";
import {getAction} from '@/api/manage'
export default {
  name: "Live",
  props: {
    msg: String
  },
  data(){
    return{
        player:null,
        live:true,
        loading:false,
        template:'mobileLive',
        url:"ezopen://open.ys7.com/1/1.live",
        Yuuid:"",
        urlLive:'',
        urlRec:'',
        data:{
            token:'at.b9foycowbqb1eed35yfses74av33e6hy-73tw2tj6uc-1ycxz22-cvo50wzmt',
            number:'',
            channelNo:''
        },
        width:void 0,
        height:void 0,
    }
  },
  created(){
    const { Yuuid,live } = this.$route.query
    if(live==1){
        this.live = true
        document.title = '直播'
    }else if(live==0){
        this.live = false
        document.title = '回放'
    }
    this.Yuuid = Yuuid
    // const queryParams = new URLSearchParams(window.location.search);
    // this.urlLive = queryParams.get('Live')
    // this.urlRec = queryParams.get('Rec')
    this.width = document.documentElement.clientWidth;
    this.height = (document.documentElement.clientWidth * 9 / 16) + 32;
  },
  mounted(){
    this.getCamera()
  },
  methods: {
    getCamera(){
        this.loading = true
        getAction('/video/admin/v1/camera/getCameraDetail',{uuid:this.Yuuid}).then(res=>{
            this.data.token = res.data.token
            this.data.number = res.data.number
            this.data.channelNo = res.data.channelNo
            let url = `ezopen://open.ys7.com/`+this.data.number+`/`+this.data.channelNo
            this.urlLive = url + '.live'
            this.urlRec = url + '.rec'
            if(this.live){
                this.url = this.urlLive
                this.template = 'mobileLive'
            }else{
                this.template = 'mobileRec'
                this.url = this.urlRec
            }
        }).catch((error)=>{
            this.$confirm('获取数据失败，请刷新重试','提示',{
                confirmButtonText: '重试',
                cancelButtonText: '取消',
                customClass:'el-message-style',
                type: 'warning'
            }).then(()=>{
                this.getCamera()
            }).catch(()=>{})
        }).finally(()=>{
            this.loading = false
            this.init()
        })
    },
    init() {
      if (this.player) {
        this.destroy();
      }
      console.log('template',this.template);
      
      this.player = new EZUIKit.EZUIKitPlayer({
            id: "video-container", // 视频容器ID
            accessToken: this.data.token,
            url: this.url,
            // simple: 极简版; pcLive: pc直播; pcRec: pc回放; mobileLive: 移动端直播; mobileRec: 移动端回放;security: 安防版; voice: 语音版;
            template: this.template,
            // plugin: ["talk"], // 加载插件，talk-对讲
            width: this.width,
            height: this.height,
            // language: "en", // zh | en
            // staticPath: "/ezuikit_static", // 如果想使用本地静态资源，请复制根目录下ezuikit_static 到当前目录下， 然后设置该值
            handleError:(e)=>{
                console.log('fail',e);
            },
            handleSuccess:(e)=>{
                console.log('success',e);
            },
          });
          window.player = this.player;
    },
    play() {
      var playPromise = this.player.play();
      playPromise.then((data) => {
        console.log("promise 获取 数据", data);
      });
    },
    callback(val){
        if(this.live==val)return
        if(this.live){
            this.template = 'mobileRec'
            this.url = this.urlRec || this.url
            document.title = '回放'
        }else{
            document.title = '直播'
            this.template = 'mobileLive'
            this.url = this.urlLive || this.url
        }
        this.live = val
        this.init()
    },
    handleYun() {
        this.$router.push({
            path:'/yun',
            query:{
                Yuuid:this.Yuuid
            }
        })
    },
    stop() {
      var stopPromise = this.player.stop();
      stopPromise.then((data) => {
        console.log("promise 获取 数据", data);
      });
    },
    getOSDTime() {
      var getOSDTimePromise = this.player.getOSDTime();
      getOSDTimePromise.then((data) => {
        console.log("promise 获取 数据", data);
      });
    },
    capturePicture() {
      var capturePicturePromise = this.player.capturePicture(
        `${new Date().getTime()}`
      );
      capturePicturePromise.then((data) => {
        console.log("promise 获取 数据", data);
      });
    },
    openSound() {
      var openSoundPromise = this.player.openSound();
      openSoundPromise.then((data) => {
        console.log("promise 获取 数据", data);
      });
    },
    closeSound() {
      var openSoundPromise = this.player.closeSound();
      openSoundPromise.then((data) => {
        console.log("promise 获取 数据", data);
      });
    },
    startSave() {
      var startSavePromise = this.player.startSave(`${new Date().getTime()}`);
      startSavePromise.then((data) => {
        console.log("promise 获取 数据", data);
      });
    },
    stopSave() {
      var stopSavePromise = this.player.stopSave();
      stopSavePromise.then((data) => {
        console.log("promise 获取 数据", data);
      });
    },
    ezopenStartTalk() {
      this.player.startTalk();
    },
    ezopenStopTalk() {
      this.player.stopTalk();
    },
    fullScreen() {
      this.player.fullScreen();
    },
    destroy() {
      var destroyPromise = this.player.destroy();
      destroyPromise.then((data) => {
        console.log("promise 获取 数据", data);
      });
      this.player = null;
    }
  },
//   destroyed(){
//     this.destroy();
//   },
};
</script>
<style>
body{
    margin: 0;
}
.el-message-style{
    width: 90% !important;
}
</style>
<style lang="scss" scoped>
.main{
    height: 100vh
}
#video-container{
    padding-bottom: 60px;
}
:deep(#mobile-ez-ptz-item .mobile-ez-ptz-container){
    width: 160px;
    height: 160px;
}
:deep(#video-container-headControl){display: none !important;}
.btn_div{
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 50px;
    background: #fff;
    display: flex;
    justify-content: space-around;
    .btn_box{
        display: flex;
        flex-direction: column;
        align-items: center;
        &>i{
            font-size: 22px;
        }
        &.active{
            color:#1989fa;
        }
        .button{
            font-size: 14px;
        }
    }
}
</style>